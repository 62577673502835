import { useState, useEffect } from 'react';
import { merge } from 'lodash';
import { baseUrl } from '../auth/drupal';

/**
 * Hook for fetching artworks by their ids. Will also fetch their images.
 * Will filter out failed fetches.
 * @param {[strings]} artwork_ids
 * @returns {[{artworks}, boolean, string]}
 */
const useGetArtworks = (artwork_ids = []) => {
  const [artworkIds, setArtworkIds] = useState(artwork_ids);
  const [artworks, setArtworks] = useState([]);
  const [loadingArtworks, setLoadingArtworks] = useState(true);
  const [message, setMessage] = useState(`Loading...`);

  useEffect(() => {
    if (artworkIds && artworkIds.length > 0) {
      setLoadingArtworks(true);
      const getArtworks = async () => {
        try {
          // map over every item and only render when they all resolve
          const items = await Promise.all(
            artworkIds.map(async (artworkId) => {
              const field_medium = 'field_artwork_medium';

              const res = await fetch(
                `${baseUrl}/jsonapi/node/artwork/${artworkId}?include=${field_medium}`
              );

              if (res.ok) {
                const data = await res.json();
                const dataDeepCopy = JSON.parse(JSON.stringify(data));

                // bring the attributes down a level
                merge(dataDeepCopy.data, dataDeepCopy.data.attributes);
                delete dataDeepCopy.data.attributes;

                if (
                  dataDeepCopy.data.relationships?.field_artist_ref?.data?.id
                ) {
                  // get the artist and mutate so that the ArtworkCard can reference the artist
                  const { attributes, id } = await getArtistData(
                    dataDeepCopy.data.relationships.field_artist_ref.data.id
                  );

                  merge(attributes, dataDeepCopy.data.attributes);

                  dataDeepCopy.data.relationships.field_artist_ref = attributes;
                  dataDeepCopy.data.relationships.field_artist_ref.id = id;
                }

                // get the artwork image and mutate so that the ArtworkCard can reference the image
                if (data.data.relationships.field_artwork_images.data[0]) {
                  const imageData = await getImageDataAttributes(
                    data.data.relationships.field_artwork_images.data[0].id
                  );

                  dataDeepCopy.data.relationships.field_artwork_images = [
                    imageData,
                  ];
                }

                if (data.included && data.included.length) {
                  const mediums = data.included.map((item) => ({
                    name: item.attributes.name,
                  }));

                  dataDeepCopy.data.relationships[field_medium] = [...mediums];
                }

                return dataDeepCopy;
              }
            })
          );

          // we may get some undefined values if the artist gives a 401
          const filteredItems = items.filter(Boolean);

          setArtworks(() => [...filteredItems]);
          setLoadingArtworks(false);
        } catch (error) {
          console.log(error);
          setMessage(
            `There was an error loading the artworks. Please reload the page. 
            If this issue persists then please get in contact with the owner of this website.`
          );
          setLoadingArtworks(false);
        }
      };

      getArtworks();
    } else {
      setMessage(`There are currently no artworks.`);
      setLoadingArtworks(false);
    }
  }, [artworkIds, setArtworkIds]);

  return { setArtworkIds, artworks, loadingArtworks, message };
};

/**
 * Get artist data attributes
 * @param {String} id
 * @returns {Object} Object of image data attributes
 */
const getArtistData = async (id) => {
  try {
    const fetchArtist = await fetch(`${baseUrl}/jsonapi/node/artist/${id}`);
    const artistRes = await fetchArtist.json();

    return artistRes.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get image data attributes
 * @param {String} id
 * @returns {Object} Object of image data attributes
 */
const getImageDataAttributes = async (id) => {
  try {
    const imageArtist = await fetch(`${baseUrl}/jsonapi/file/file/${id}`);
    const imageRes = await imageArtist.json();

    return imageRes.data.attributes;
  } catch (error) {
    console.log(error);
  }
};

export default useGetArtworks;
