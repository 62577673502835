import React from 'react';
import { Link } from 'gatsby';

const UserHeading = ({
  username,
  savedArtwork = false,
  favouriteArtists = false,
  account = false,
}) => {
  return (
    <div className="user-subnav">
      <div className="grid-section user-title grid-content-container">
        <h1 className="t-xlarge t-heading">
          {username ? username : 'Username placeholder'}
        </h1>
      </div>
      <nav className="navbar inline">
        <div className="sub-menu-wrapper user">
          <div className="grid-section grid-content-container">
            <ul>
              <li>
                <Link
                  className={`navbar-item ${savedArtwork ? 'active' : ''}`}
                  to="/user/saved-artworks"
                >
                  Saved Artworks
                </Link>
              </li>
              <li>
                <Link
                  className={`navbar-item ${favouriteArtists ? 'active' : ''}`}
                  to="/user/favourite-artists"
                >
                  Favourite Artists
                </Link>
              </li>
              <li>
                <Link
                  className={`navbar-item ${account ? 'active' : ''}`}
                  to="/user/"
                >
                  My Account
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default UserHeading;
