export const priceFixed = (price) => {
    if (price && parseInt(price.number)) {
        return price.currency_code + ' $' + parseInt(price.number).toFixed(2)
    }
    return null
}

export const monthlyPriceFixed = (price) => {
    if (price && parseInt(price.number)) {
        return price.currency_code + ' $' + (parseInt(price.number)  * 0.025).toFixed(2)
    }
    return null
}

export const buyoutPriceFixed = (price) => {
    if (price && parseInt(price.number)) {
        return price.currency_code + ' $' + (parseInt(price.number) * 0.4).toFixed(2)
    }
    return null
}

export const buyout5PriceFixed = (price) => {
    if (price && parseInt(price.number)) {
        return price.currency_code + ' $' + (parseInt(price.number) * 0.1).toFixed(2)
    }
    return null
}

/**
 * Get artwork dimensions using the width, height, and depth.
 * @param {Object.<string, number>} artwork 
 * @returns {?string} Dimensions string
 */
export const artworkDimensions = (artwork) => {
    let dimensions = ''
    if (artwork?.field_dimensions_computed) {
        return artwork.field_dimensions_computed;
    }
    if (artwork.field_height && parseFloat(artwork.field_height)) {
        dimensions += `${ parseFloat(artwork.field_height)}`
    }
    if (artwork.field_width && parseFloat(artwork.field_width)) {
        dimensions += ` x ${parseFloat(artwork.field_width)}`
    }
    if (artwork.field_depth && parseFloat(artwork.field_depth)) {
        dimensions += ` x ${parseFloat(artwork.field_depth)}`
    }
    dimensions += dimensions ? ` in` : '';
    
    return dimensions ? dimensions : null
}