import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/user/context';
import { isLoggedIn, getWithExpiry } from '../../util/auth/drupal';
import { navigate } from 'gatsby';

/* This check is to ensure that this code gets executed in browser because
 * If we run this code without this check your gatsby develop will fail as it won't be able
 * to access localStorage on build time
 */
const useAuthGate = (pathToNavigateTo = '/') => {

  const { state, userLogout } = useContext(UserContext);
  const { user } = state;

  const redirect = () => {
    navigate(pathToNavigateTo);
  }

  useEffect(() => {

    const checkLogin = async () => {
      if (user.isLoggedIn !== true) {
        try {
          const res = await isLoggedIn();
          if (Number(res)) {
            return true;
          } else {
            userLogout();
            redirect();
            return false;
          }
        } catch (error) {
          userLogout();
          redirect();
          console.log(`error: ${error.message}`);
        }
      }
    };
    
    const loggedin = checkLogin();

    if (!loggedin) {
      alert('Your session has expired. Please log in');
    }
  
  }, []);

  return;
};

export default useAuthGate;
