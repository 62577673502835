/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'gatsby';

import ImageFromField from './ImageFromField';
import { priceFixed, artworkDimensions } from '../helper/artwork';
import ActionButtons, { FollowBtn } from './ActionButtons';
import { usePrintArtwork } from '../../util/hooks/usePrintArtwork';

const ArtworkCard = ({
  artwork, 
  device,
  artist_drupal_id = '',
  className = '',
  isSold = false,
}) => {
  const [isPrinting, setIsPrinting] = usePrintArtwork();
  const price_available_on_request_label = 'Price available on request';
  const artwork_price = priceFixed(artwork.field_art_price_original);
  const display_price = !Boolean(artwork.field_price_avail_on_request)
    ? artwork_price
    : price_available_on_request_label;
  const urlBase = typeof window !== 'undefined' ? window.location.origin : '';

  const artworkId = artwork.drupal_id ? artwork.drupal_id : artwork.id;
  const artistId =
    artist_drupal_id || artwork?.relationships?.field_artist_ref?.drupal_id;

  let CardClickComponent = Link;

  return (
    <div
      className={`artwork artwork-card ${className} ${
        isPrinting ? 'is-print-target' : ''
      }`}
    >
      <div className="artwork-image">
        <Link to={artwork.path.alias}>
          <ImageFromField
            img={artwork.relationships.field_artwork_images[0]}
            device={device}
            imageSize="gatsby_artwork_thumbnail"
            /* format="json" */
          />
        </Link>
      </div>
      <div className="control artwork-data">
        {artwork.relationships?.field_artist_ref ? (
          <ul className="artwork-info  t-center">
            <li>
              <h3 className="artist-title">
                <CardClickComponent
                  to={artwork.path.alias}
                  title={`${artwork.relationships?.field_artist_ref?.title} - ${artwork.field_artwork_name}`}
                >
                  {artwork.relationships?.field_artist_ref?.title}
                </CardClickComponent>
              </h3>
            </li>
            <FollowBtn drupal_id={artistId} />
          </ul>
        ) : null}
        <ul className="piece-details t-center">
          <li>
            <h3>{artwork.field_artwork_name}</h3>
          </li>
          <li>
            {artwork.relationships.field_artwork_medium &&
            artwork.relationships.field_artwork_medium.length > 0
              ? artwork.relationships.field_artwork_medium
                  .map((medium) => {
                    return medium.name;
                  })
                  .join(', ')
              : null}
          </li>
          <li>{artworkDimensions(artwork)}</li>
          <li>{artwork.field_year_or_circa}</li>
          {artwork?.relationships?.field_artwork_status?.name === 'Sold' ||
          artwork?.relationships?.field_artwork_status?.data?.id === "3e499e4e-8f77-42d9-b645-7129f448bc93" ||
              artwork?.status === false ||
              isSold ? (
                <li className="label-sold">Sold</li>
              ) : (
                <li>{display_price}</li>
          )}
          <ActionButtons
            showText={false}
            drupal_id={artworkId}
            url={`${urlBase}${artwork.path.alias}`}
            title={artwork.field_artwork_name || artwork.title}
            artist_title={artwork.relationships.field_artist_ref?.title}
            location={artwork.relationships?.field_location_ref?.title}
            showToolTips={true}
            centerToolTips={true}
            setIsPrinting={setIsPrinting}
          />
        </ul>
      </div>
    </div>
  );
};

export default ArtworkCard;
