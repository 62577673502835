/* eslint-disable react/jsx-indent */
import React, { useState, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/Layout';
import UserHeading from '../../components/global/UserHeading';
import ArtworkCard from '../../components/global/ArtworkCard';
import CompLink from '../../components/global/Link';
import { UserContext } from '../../context/user/context';
import useAuthGate from '../../util/hooks/useAuthGate';
import SEO from '../../components/SEO';
import useGetArtworks from '../../util/hooks/useGetArtworks';

const UserSavedArtworkPage = ({ data }) => {
  const [device, setDevice] = useState('mobile');
  const { state } = useContext(UserContext);
  const { user } = state;
  useAuthGate('/user/login');

  const {
    setArtworkIds,
    artworks: savedArtworks,
    loadingArtworks,
    message,
  } = useGetArtworks(user.field_artwork_saved);

  useEffect(() => {
    setArtworkIds(user.field_artwork_saved);
  }, [user.field_artwork_saved, setArtworkIds]);

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      footerMenu={data.footer_menu}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
    >
      <SEO title="Saved Artworks" />
      <UserHeading savedArtwork={true} username={user.title} />
      <main className="user-page">
        <section className="grid-section grid-content-container">
          <h1 className="t-xlarge t-heading t-uppercase sub-section-title">
            Saved Artworks
          </h1>
          <div className="spacer"></div>
          {loadingArtworks ? (
            <div className="artworks grid-3">
              {new Array(3).fill(0).map((_, i) => (
                <div key={i} className="artwork-skeleton grid-col"></div>
              ))}
            </div>
          ) : savedArtworks && savedArtworks.length > 0 ? (
            <>
              <div className="artworks grid-3">
                {savedArtworks.map(({ data: artwork }, i) => {
                  const isStatusSold = artwork.relationships?.field_artwork_status?.data?.id === "3e499e4e-8f77-42d9-b645-7129f448bc93";
                  return (
                  <ArtworkCard
                    artwork={artwork}
                    device={device}
                    key={artwork.id}
                    artist_drupal_id={artwork.relationships.field_artist_ref.id}
                    isSold={isStatusSold}
                    className={'grid-col'}
                  />
                )})}
              </div>
              <div className="spacer"></div>
            </>
          ) : (
            <>
              <h4>{message}</h4>
              <div className="spacer"></div>
              <CompLink
                button
                className={`btn-large btn-center btn-space-large`}
                path={`/artworks/`}
                linkkey={`search_btn`}
                device={device}
              >
                Browse artwork
              </CompLink>
            </>
          )}
        </section>
      </main>
    </Layout>
  );
};

export default UserSavedArtworkPage;

export const UserSavedArtworkPageQuery = graphql`
  query UserSavedArtworkPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;
