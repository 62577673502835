import { useState, useEffect } from 'react';

/**
 * add printing state and remove after print window is closed
 * @returns {[Boolean, function(): void]}
 */
export const usePrintArtwork = () => {
  const [isPrinting, setIsPrinting] = useState(false);

  const resetArtwork = () => {
    setIsPrinting(false);
    document.querySelector('body').classList.remove('is-artwork-printing');
  };

  useEffect(() => {
    window.addEventListener('afterprint', resetArtwork);

    if (isPrinting) {
      document.querySelector('body').classList.add('is-artwork-printing');
      window.print();
    }

    return () => {
      window.removeEventListener('afterprint', resetArtwork);
    };
  }, [isPrinting]);

  return [isPrinting, setIsPrinting];
};
